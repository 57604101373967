//Global keys
let locales = {
    "sidebar_companiesMenu": "Campagna Email",
    "sidebar_Companies": "Affiliati 🤝",
    "sidebar_lista1": "Servizi Marketing",
    "sidebar_list2": "Partner Affiliati",
    "sidebar_Agenti": "Agenti",
    "sidebar_areaManager": "Area Manager",
    "sidebar_emailSentList": "Email Inviate (controllo)",
    "sidebar_parametri": "Archivio Email (controllo)",
    "sidebar_listParametri": "Configurazioni",
    "sidebar_workFlowManagement": "Flusso di lavoro",
    "sidebar_emailProgramming": "Registro Promemoria ✉",
    "sidebar_documentsAndContracts": "Documenti e Contratti",
    "sidebar_documentsUpdated": "Files Aggiornati",
    "sidebar_usersManagement": "Gestione degli utenti",
    "sidebar_roles": "Roles",
    "sidebar_permissions": "Permissions",
    "sidebar_users": "Users",
    "sidebar_emailCampaign": "Campagna Email",
    "sidebar_ReportCSVMenu": "Rapporto CSV degli annunci"
};

export default locales;