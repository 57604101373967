//Global keys
let locales = {
    "sidebar_companiesMenu": "Campagna Email",
    "sidebar_Companies": "Afiliados 🤝",   
    "sidebar_lista1": "Servicio de Marketing", 
    "sidebar_list2": "Afiliación de socios", 
    "sidebar_Agenti": "Agente", 
    "sidebar_emailSentList": "Correo electrónico enviado (control)", 
    "sidebar_parametri": "Archivo de correo electrónico (control)",
    "sidebar_listParametri": "Configuración", 
    "sidebar_workFlowManagement": "Flujo de trabajo",  
    "sidebar_emailProgramming": "Registro de emails ✉",  
    "sidebar_documentsAndContracts": "Documentos y Contratos", 
    "sidebar_documentsUpdated": "Documentos Actualizados",
    "sidebar_usersManagement": "Administración de usuarios",
    "sidebar_roles": "Roles",
    "sidebar_permissions": "Permisos",
    "sidebar_users": "Usuarios",
    "sidebar_emailCampaign": "Campaña por Email",
    "sidebar_ReportCSVMenu": "Reporte CSV de Anuncios"
};

export default locales;