import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Form, Input, Skeleton, notification, Select } from "antd";

import { reset, saveEditForm, getFormById, getAvailableStatus, setStatusId } from "./redux/slice";
import ListQuestions from "../../components/ListQuestions";

function EditFormView() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = intl;
    const [notificationApi, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { data, loading, resultError, result, availableStatus } = useSelector(
        (state) => state.formsEditRedux
    );

    useEffect(() => {
        if (id) {
            dispatch(getFormById({ id }))
        } else {
            dispatch(reset('data'))
            form.resetFields()
            dispatch(getAvailableStatus())
        }


        return () => {
            dispatch(reset())
        }
    }, [])

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
            });
        }

    }, [data]);

    useEffect(() => {
        dispatch(reset({ result: null, resultError: null }));

        if (result) {
            notificationApi.success({
                message: messages["saveSuccessfullyTitle"].toString(),
                description: messages["saveSuccessfully"].toString(),
            });
            dispatch(getFormById({ id: result.id }))
        }

        if (resultError) {
            const { error } = resultError;
            notificationApi.error({
                message: messages["saveError"]?.toString(),
                description: messages[error] || error,
            });
        }
    }, [resultError, result]);

    const statusList = useMemo(() => {
        let _statusList = availableStatus ? [...availableStatus] : []
        if (data && data?.status) {
            _statusList = [data.status, ...availableStatus]
        }
        return _statusList.map(v => ({ value: v.id, label: v.name }))
    }, [availableStatus, data])

    const onClose = () => {
        navigate(`/admin/management/forms/list`);
        form.resetFields()
    }

    const required = {
        required: true,
        message: messages["required_field"],
    }

    return (
        <>
            {contextHolder}
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="container-fluid">
                    <div className="card card-body col-12 col-md-6 mx-auto">
                        <Form
                            form={form}
                            key="form-editForm"
                            layout="vertical"
                            onFinish={(values) => {
                                dispatch(saveEditForm({ id, ...values }));
                            }}
                            onValuesChange={(changedValues) => {
                                if(changedValues?.statusId){
                                    dispatch(setStatusId(changedValues?.statusId))
                                }
                            }}
                        >
                            {loading ? (
                                <>
                                    <Skeleton active />
                                </>
                            ) : (
                                <div className="row g-3">
                                    <Form.Item
                                        className="col-12"
                                        label={<label className="form-label">{messages["dashboardList_status"]}</label>}
                                        rules={[required]}
                                        name="statusId"
                                    >
                                        <Select className="w-100" options={statusList} />
                                    </Form.Item>

                                    <Form.Item
                                        className="col-12"
                                        label={<label className="form-label">{messages["forms_title"]}</label>}
                                        name="title"
                                        rules={[required]}
                                    >
                                        <Input
                                            className="form-control"
                                            placeholder={messages[
                                                "forms_title"
                                            ]?.toString()}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="col-12"
                                        label={<label className="form-label">{messages["forms_description"]}</label>}
                                        name="description"
                                        rules={[required]}
                                    >
                                        <Input.TextArea
                                            className="form-control"
                                            maxLength={500}
                                            placeholder={messages[
                                                "forms_description"
                                            ]?.toString()}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="col-12"
                                        name="questions"
                                        label={<label className="form-label">{messages["forms_questions"]}</label>}
                                        rules={[required]}
                                    >
                                        <ListQuestions form={form} isEditForm={id} />
                                    </Form.Item>
                                </div>
                            )}
                        </Form>
                        <div className="row pt-5">
                            <div className="text-center pt-10">
                                <button
                                    className="btn btn-primary me-sm-3 me-1"
                                    disabled={loading}
                                    onClick={() => form.submit()}
                                >
                                    {messages["save"]?.toString()}
                                </button>
                                <button
                                    className="btn btn-label-secondary"
                                    onClick={onClose}
                                >
                                    {messages["close"]?.toString()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditFormView