import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { resetALL } from "../../../../../../../config/redux/actions";


//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    status: null,
    result: null,
    error: null,
};

const route = 'modules/client/dashboard/modals/dinamicFormByStep/slice'


//Export async Action

export const dinamicFormOpen = createAsyncThunk('dashboard/dinamicFormByStep/open', async ({ status }, { rejectWithValue, dispatch }) => {
    try {
        let data = await clientQuery(
            `
            query getFormByStatusId($id: Int!) {
                result: getFormByStatusId(id: $id) {
                    id
                    title
                    description
                    statusId
                    status {
                        id
                        name
                        parentId
                    }
                    questions {
                        id
                        formId
                        question
                        helpText
                        props
                        questionType
                        typeData
                        position
                        catalog {
                            id
                            items {
                                id 
                                catalogId
                                value
                            }
                            nameCatalog
                        }
                    }
                }
            }
            `,
            {
                id: Number(status)
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data.result
    } catch (exc) {
        ExceptionManager(exc, route, 'dinamicFormOpen', dispatch);
        return rejectWithValue(exc)
    }
});


export const saveAnswersForm = createAsyncThunk('dashboard/dinamicFormByStep/save', async ({ formId, answers, userAnnouncementId, newStatus }, { rejectWithValue, dispatch }) => {

    try {

        let result

        let answersWithFiles = []
        let _answers = { ...answers }

        for (const key in answers) {
            const value = answers[key];
            if(Array.isArray(value) && value[0]?.file){
                answersWithFiles.push({
                    questionId: key,
                    files: value
                })
                delete _answers[key]
            }
        }

        if (formId) {
            result = await clientMutation(`
                mutation saveFormAnswer ($formId: Int!, $answers: String!, $userAnnouncementId: Int, $answersUpload: [AnswerUploadInput], $newStatus: Int) {
                    saveFormAnswer (formId: $formId, answers: $answers, userAnnouncementId: $userAnnouncementId, answersUpload: $answersUpload, newStatus: $newStatus)
                }
                `, {
                formId,
                answers: JSON.stringify(_answers),
                userAnnouncementId,
                answersUpload: answersWithFiles,
                newStatus,
            }, endpoints.GRAPHQL_GENERAL
            );
        }
        return result?.saveFormAnswer
    } catch (exc) {
        ExceptionManager(exc, route, 'saveAnswersForm', dispatch);
        return rejectWithValue(exc)
    }
});

//Slice

const dinamicFormByStateRedux = createSlice({
    name: 'dashboard/dinamicFormByStep',
    initialState: INIT_STATE,
    reducers: {
        dinamicFormClose(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk dinamicFormOpen
        builder.addCase(dinamicFormOpen.pending, (state, action) => {
            state.loading = true
            state.isOpen = true
            state.status = action.meta.arg?.status
        })
        builder.addCase(dinamicFormOpen.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
        })
        builder.addCase(dinamicFormOpen.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
        //thunk saveAnswersForm
        builder.addCase(saveAnswersForm.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(saveAnswersForm.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload
        })
        builder.addCase(saveAnswersForm.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    }
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dinamicFormByStateRedux;

// Extract and export each action creator by name
export const { dinamicFormClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;