import { Popover } from 'antd';
import moment from 'moment/moment';
import React, { useMemo } from 'react'
import { FaFile } from "react-icons/fa6";

export const RenderAnswersForm = (props) => {

    const { answers, form } = props;

    const renderAnswers = useMemo(() => {
        if (form && answers) {
            const _answers = JSON.parse(answers?.answers);
            return form.questions.map(q => {
                let answerContent;
                switch (q.typeData) {
                    case 'boolean':
                        answerContent = _answers[q.id] ? 'Si' : 'No';
                        break;
                    case 'string':
                        answerContent = _answers[q.id];
                        break;
                    case 'catalog':
                        answerContent = _answers[q.id];
                        break;
                    case 'date':
                        Array.isArray(_answers[q.id]) ? answerContent = `${moment(_answers[q.id][0]).format('l')} - ${moment(_answers[q.id][1]).format('l')}` : answerContent = moment(_answers[q.id]).format('l')
                        break;
                    case 'number':
                        answerContent = _answers[q.id];
                        break;
                    case 'file':
                        answerContent = _answers[q.id]?.map((file) => {
                            return (
                                <div key={file.id}>
                                    <Popover
                                        trigger="hover"
                                        content={file.name}
                                    >
                                        <a href={file.url} target='_blank'>
                                            <FaFile size={25} />
                                        </a>
                                    </Popover>
                                </div>
                            )
                        })
                        break;
                    case 'update_status':
                        answerContent = _answers[q.id]?.label
                        break;
                }
                return (
                    _answers[q.id] !== undefined && (
                        <tr key={q.id}>
                            <th scope="row">{q.question}</th>
                            <td>{answerContent}</td>
                        </tr>
                    )
                );
            });
        }
        return null;
    }, [answers, form])

    return (
        <div className='col-12 row'>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Domanda</th>
                        <th scope="col">Risposta</th>
                    </tr>
                </thead>
                <tbody>
                    {renderAnswers}
                </tbody>
            </table>
        </div>
    )
}
