import { Form, Input, Modal, notification, Space } from "antd";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "components";
import {
    reset,
    statusLeadsEditReduxClose,
    statusLeadsEditSave,
} from "./redux/slice";

export const EditStatusLeads = ({ onClose }) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const { loading, isOpen, data, result, error } = useSelector(
        (state) => state.statusLeadsEditRedux
    );

    //Verificacion de carga
    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
            });
        }
        if (result) {
            dispatch(reset({ result: null }));
            notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
            closeModal(true);
        }

        if (error) {
            dispatch(reset({ error: null }));
            notification.error({
                message: messages["saveError"],
                description: messages[error] || error,
            });
        }
    }, [data, result, error]);

    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        form.resetFields();
        dispatch(statusLeadsEditReduxClose());
        onClose && onClose(refresh);
    });

    return (
        <Modal
            open={isOpen}
            width={800}
            onCancel={() => closeModal()}
            footer={null}
            maskClosable={false}
        >
            <div className="p-3 p-md-5">
                <div className="text-center mb-4">
                    <h3>
                        {data?.id
                            ? messages["configStatusLeadsEdit_update"]
                            : messages["configStatusLeadsEdit_create"]}
                    </h3>
                </div>
            </div>
            <Loading loading={loading}>
                <div className="card-body">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={(values) => {
                            dispatch(
                                statusLeadsEditSave({
                                    id: data.id,
                                    ...values,
                                })
                            );
                        }}
                    >
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 m-2">
                                        <Form.Item
                                            name="name"
                                            label={
                                                <label className="form-label">
                                                    {
                                                        messages[
                                                            "configStatusLeadsEdit_name"
                                                        ]
                                                    }
                                                </label>
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        messages[
                                                            "required_field"
                                                        ],
                                                },
                                            ]}
                                        >
                                            <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 m-2">
                                        <Form.Item
                                            name="description"
                                            label={
                                                <label className="form-label">
                                                    {
                                                        messages[
                                                            "configStatusLeadsEdit_description"
                                                        ]
                                                    }
                                                </label>
                                            }
                                            /* rules={[
                                          {
                                            required: true,
                                            message: messages["required_field"],
                                          },
                                        ]} */
                                        >
                                            <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 m-2">
                                        <Form.List
                                            name="substatus"
                                            label={
                                                <label className="form-label">
                                                    Stati secondari
                                                </label>
                                            }
                                        >
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(
                                                        ({
                                                            key,
                                                            name,
                                                            ...restField
                                                        }) => (
                                                            <Space
                                                                key={key}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    marginBottom: 8,
                                                                }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "name",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                messages[
                                                                                    "required_field"
                                                                                ],
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input  placeholder={messages[
                                                                                    "configStatusLeadsEdit_name"
                                                                                ]}/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "description",
                                                                    ]}
                                                                >
                                                                    <Input placeholder={messages[
                                                                                    "configStatusLeadsEdit_description"
                                                                                ]} />
                                                                </Form.Item>
                                                                {key + 1 > data.substatus.length && <button
                                                                    className="btn btn-label-secondary"
                                                                    onClick={() =>
                                                                        remove(
                                                                            name
                                                                        )
                                                                    }>
                                                                    {messages['delete']}
                                                                </button>}
                                                            </Space>
                                                        )
                                                    )}
                                                    <Form.Item>
                                                        <button
                                                            className="btn btn-label-primary"
                                                            onClick={() =>
                                                                add()
                                                            }>
                                                            {messages['add']} stati secondari
                                                        </button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Loading>
            <div className="row pt-5">
                <div className="col-12 text-center">
                    <button
                        type="button"
                        className="btn btn-primary me-sm-3 me-1"
                        onClick={() => form.submit()}
                    >
                        {messages["save"]}
                    </button>
                    <button
                        type="button"
                        className="btn btn-label-secondary"
                        onClick={() => closeModal()}
                    >
                        {messages["cancel"]}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
