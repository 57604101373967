import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    error: null,
};

const route = 'modules/client/dashboard/modals/announcementNoteDetail/slice'

//Extend async Action
export const detailNoteOpen = createAsyncThunk('dashboard/announcementNoteDetail/open', async (
    {announcementNoteId},
    { rejectWithValue, dispatch }
) => {
    try {

        let data = await clientQuery(
            `query getUserAnnouncementNoteById($id: Int) {
                note: getUserAnnouncementNoteById(id: $id) {
                    id
                    userId
                    userAnnouncementId
                    note
                    formAnswer {
                        id
                        answers
                    }
                    form{
                        questions{
                            id
                            question
                            questionType
                            typeData
                        }
                    }
                }
            }`,
            {
                id: Number(announcementNoteId)
            },
            endpoints.GRAPHQL_GENERAL
        );
        //Se retorna la data del server
        return data.note 
    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'dashboardEditNoteOpen', dispatch);
        return rejectWithValue(exc)
    }
})


//Slice
const announcementNoteDetailRedux = createSlice({
    name: 'dashboard/announcementNoteDetail',
    initialState: INIT_STATE,
    reducers: {
        detailNoteClose(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(detailNoteOpen.pending, (state) => {
            state.loading = true
            state.isOpen = true
        })
        builder.addCase(detailNoteOpen.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(detailNoteOpen.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = announcementNoteDetailRedux;

// Extract and export each action creator by name
export const { detailNoteClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;