import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../config/helpers/GraphQLApi';
import moment from "moment";

import { endpoints } from '../../../../config/constants';
import { ExceptionManager } from '../../../../config/helpers/Logging'
import { resetALL } from '../../../../config/redux/actions'

//STATE INITIAL
const INIT_STATE = {
    loadingRoles: false,
    roles: [],
    loadingUsers: false,
    users: [],
    loadingStates: false,
    states: [],
    loadingPAStates: false,
    pAStates: [],
    loadingPermissions: false,
    permissions: [],
    loadingCompanies: false,
    companies: [],
    loadingParametrizableTexts: false,
    parametrizableTexts: [],
    loadingAgents: false,
    agents: [],
    loadingAreaManager: false,
    areaManager: [],
    loadingAnnouncemetStatus: false,
    announcementStatusList: [],
    webPages: [],
    loadingWebPages: false
};

const route = 'modules/common/catalog/redux/slice'

const getCatalogInfo = (key, force, query, localData, querys) => {

    let getCatalog = true;
    let tempdata = sessionStorage.getItem('catalog_' + key);
    if (tempdata) tempdata = JSON.parse(tempdata);

    if (tempdata && tempdata.date && !force) {
        let date = moment(tempdata.date).add(60, 'seconds');
        if (date > moment()) {
            getCatalog = false;
        }
    }

    if (getCatalog) {
        querys.push(query);
    }
    else
        localData[key] = tempdata.items;

    return [querys, localData]
}


//Extend async Action
export const getCatalog = createAsyncThunk(
    'catalog/getCatalog',
    async (
        { keys, force },
        { dispatch, rejectWithValue }
    ) => {

        try {
            let querys = [];
            let localData = {};
            let loadings = {};
            let endpoint = endpoints.GRAPHQL_GENERAL

            keys.forEach((key) => {
                let _key = key;
                let params = '';
                if (typeof key === "object") {
                    _key = key.key
                    if (key?.params) {
                        params += '(';
                        for (const param in key.params) {
                            const value = key.params[param];
                            params += `${param}: ${value}, `
                        }
                        params += ')';
                    }
                }
                switch (_key) {
                    case 'states':
                        loadings.loadingStates = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            states: getStates {
                                id
                                name
                                region
                                abrev
                            }`,
                            localData, querys)
                        break;

                    case 'roles':
                        loadings.loadingRoles = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            roles: getRoles {
                                id
                                name
                            }`,
                            localData, querys)
                        break;

                    case 'users':
                        loadings.loadingUsers = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                                users: getUsers ${params} {
                                    id
                                    username
                                    fullName
                                    imageUrl
                                    paridaIVA
                                    roles {
                                        id
                                    }
                                }`,
                            localData, querys)
                        break;
                    case 'contact':
                        loadings.loadingContact = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                                    contact: getContactForm {
                                        id
                                        value
                                    }`,
                            localData, querys)
                        endpoint = endpoints.GRAPHQL_AUTH
                        break;
                    case 'permissions':
                        loadings.loadingPermissions = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                                permissions: getPermissions(includeRemoved: false) {
                                    id
                                    parentId
                                    hash
                                    name
                                    description
                                    isSingle
                                }`,
                            localData, querys)
                        break;
                    case 'companies':
                        loadings.loadingCompanies = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            companies: getCompanies ${params} {
                                id
                                name
                                userId
                                email
                                state
                                city
                                phone1
                                phone2
                                status
                                workState
                                workActivity
                                paridaIVA
                                price
                                address
                                freeStartDate
                                freeEndDate
                                paymentMethod
                                businessCode
                                isDeleted
                            }`,
                            localData, querys)
                        break;

                    case 'parametrizableTexts':
                        loadings.loadingParametrizableTexts = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                                parametrizableTexts: getTextsByPage ${params} {
                                    id
                                    uri
                                    rolesId
                                    description
                                    content
                            }`,
                            localData, querys)
                        break;

                    case 'pAStates':
                        loadings.loadingPAStates = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            pAStates: getAllPAStates {
                                id
                                name
                                color
                                description
                                helpText
                                limitHours
                                limitAttemps
                            }
                        `,
                            localData, querys);
                        break;
                    case 'agents':
                        loadings.loadingAgents = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                                agents: getUsers (roleId: 5, all: true, ${params.slice(1, params.length - 1)}) {
                                    id
                                    username
                                    fullName
                                    imageUrl
                                    isDeleted
                                }`,
                            localData, querys)
                        break;

                    case 'areaManager':
                        loadings.loadingAreaManager = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            areaManager: getUsers (roleId: 8, all: true) {
                                        id
                                        username
                                        fullName
                                        imageUrl
                                    }`,
                            localData, querys)
                        break;
                    case 'announcementStatus':
                        loadings.loadingAnnouncemetStatus = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            announcementStatusList: getAnnouncementStatusList {
                                name
                                id
                                parentId
                                description
                                substatus {
                                    name
                                    id
                                }
                            }`,
                            localData, querys)
                        break;
                    case 'webPages':
                        loadings.loadingWebPages = true;
                        [querys, localData] = getCatalogInfo(_key, force, `
                            webPages: getAllWebPages {
                                id
                                url
                                title
                            }`,
                            localData, querys)
                        break;
                }
            });

            dispatch(catalogLoadings(loadings));

            let finalQuery = 'query { ';
            querys.forEach((x) => {
                finalQuery += x;
            });
            querys.forEach((x) => {
                finalQuery += x;
            });
            finalQuery += ' }'

            let data = {};
            if (querys.length > 0)
                data = await clientQuery(finalQuery, {}, endpoint);

            keys.forEach((key) => {
                let _key = key;
                if (typeof key === "object") {
                    _key = key.key
                }
                if (data[_key])
                    sessionStorage.setItem(
                        'catalog_' + key,
                        JSON.stringify({ date: moment(), items: data[_key] }),
                    );
            });

            for (let i in loadings) {
                loadings[i] = false;
            }

            return { ...data, ...localData, ...loadings };

        } catch (exc) {
            ExceptionManager(exc, route, 'getCatalog');
            return rejectWithValue(exc)
        }
    })

//Slice
const companiesListRedux = createSlice({
    name: 'common/catalogRedux',
    initialState: INIT_STATE,
    reducers: {
        catalogLoadings(state, action) {
            return { ...state, ...action.payload }
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getCatalog.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getCatalog.fulfilled, (state, { payload }) => {
            return { ...state, ...payload }
        })
        builder.addCase(getCatalog.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companiesListRedux

// Extract and export each action creator by name
export const { catalogLoadings, reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer