let locales = {
    "reportCSV_haveAcquierd": "HAN COMPRADO",
    "reportCSV_actionsPerformed": "ACCIONES REALIZADAS",
    "reportCSV": "Exportar csv",
    "reportCSV_firstName": "Primer nombre",
    "reportCSV_lastName": "Apellido",
    "reportCSV_phone": "Teléfono",
    "reportCSV_email": "Correo electrónico",
    "reportCSV_tags": "Etiquetas",
    "reportCSV_city": "Ciudad",
    "reportCSV_state": "Estado",
    "reportCSV_province": "Provincia",
    "reportCSV_titleRequest": "Título de la Solicitud",
    "reportCSV_dataRequest": "Fecha de la Solicitud",
    "reportCSV_categorie": "Categorías",
    "reportCSV_description": "Descripción",
    "reportCSV_expectedProjectDate": "Fecha Prevista del Proyecto",
    "reportCSV_requestType": "Tipo de Solicitud",
    "reportCSV_whereIsNeedit": "Dónde lo Necesita",
    "reportCSV_searchthing": "Qué Está Buscando",
    "reportCSV_inspection": "Inspección",
    "reportCSV_requestCode": "Código de Solicitud",
    "reportCSV_desingAndEvaluation": "Diseño y/o Evaluación",
    "reportCSV_requestPermissions": "Solicitud de Permisos",
    "reportCSV_receiveQuotations": "Quiero recibir presupuestos solo de empresas de mi zona (Provincia)"
};


export default locales;