let locales = {
    "forms_menu": "Formularios",
    "forms_title": "Título",
    "forms_description": "Descriptión",
    "forms_questions": "Preguntas",
    "forms_selectStatusFirst": "Selecciona un estado en la vista principal primero",
    "forms_questionRequired": "*Esta pregunta es obligatoria"
};

// merge modal SubmitDocument
import list from '../../views/list/lan/es'

locales = {
    ...locales,
    ...list,
};

export default locales;