import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";



//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    error: null,
     //
     loadingDelete: false,
     errorDelete: null,
     successDelete: null,
};

const route = "modules/management/parameters/views/list/parametrizableTexts/redux/slice";

export const parametrizableTextsSearch = createAsyncThunk('parameters/list/parametrizableTextsSearch', async (
    { searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query searchTextsByPage($searchText: String, $limit: Int, $offset: Int) {
                result: searchTextsByPage(searchText: $searchText, limit: $limit, offset: $offset) {
                  items {
                    id
                    roles {
                      name
                    }
                    webPage {
                        title
                    }
                    uri
                    rolesId
                    description
                    content
                  }
                  count
                }
              }`,
            {
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        )

        return data?.result || []
    } catch (exc) {
        ExceptionManager(exc, route, 'parametrizableTextsSearch', dispatch);
        return rejectWithValue(exc)
    }
})

export const parametrizableTextFromDelete = createAsyncThunk('parameters/list/parametrizableTextFromDelete', async ({ id },
    { rejectWithValue, dispatch }) => {

    try {
        // console.log(id, "id se paso al slice?")
        let result = await clientMutation(
            `mutation DeleteTextByPage($id: Int!) {
                    deleteTextByPage(id: $id)
                  }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        // console.log(result, "despues de la peticion al graph")
        return result

    } catch (exc) {
        ExceptionManager(exc, route, 'parametrizableTextFromDelete', dispatch);
        return rejectWithValue(exc)
    }

});



//Slice
const parametrizableTextsListRedux = createSlice({

    name: 'parameters/list/parametrizableTextsSearch',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(parametrizableTextsSearch.pending, (state) => {
            state.loading = true
        })
        builder.addCase(parametrizableTextsSearch.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(parametrizableTextsSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        // delete parametrizabletext from

        builder.addCase(parametrizableTextFromDelete.pending, (state) => {
            state.loadingDelete = true
        })
        builder.addCase(parametrizableTextFromDelete.fulfilled, (state, { payload }) => {
            state.loadingDelete = false
            state.successDelete = payload
        })
        builder.addCase(parametrizableTextFromDelete.rejected, (state, action) => {
            state.loadingDelete = false
            state.errorDelete = action.error.message
        })

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = parametrizableTextsListRedux;

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer