//Global keys
let locales = {
    "dashboard_menu": "Gestione Lead",
    "dashboard_menu_programmigEmail": "Registro Promemoria ✉",
};

//Merge Modules
import list from '../../views/list/lan/it'
import detail from '../../views/detail/lan/it'
import statusChangesHistory from '../../modals/statusChangesHistory/lan/it'
import editNote from '../../modals/editNote/lan/it';
import detailNote from '../../modals/announcementNoteDetail/lan/it';

locales = {
    ...locales,
    ...list,
    ...detail,
    ...statusChangesHistory,
    ...editNote,
    ...detailNote,
};

export default locales;