import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Input, Space, notification } from "antd";

import { statusLeadsEditOpen, statusLeadsEditSave } from "../../../.config/redux/actions";
import {reset} from '../../../parameters/modals/editSatutsLeads/redux/slice'

function UpdateStatusProps(props) {
    const { onChange } = props;
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const { result, error } = useSelector((st) => st.statusLeadsEditRedux);
    const { data: formData, statusId } = useSelector((st) => st.formsEditRedux);
    const [options, setOptions] = useState([]);
    const [currInput, setCurrInput] = useState('')
    const _statusId = statusId || formData?.statusId;

    useEffect(() => {
        //get subStates
        if (_statusId) {
            //Verify if statusId was selected
         
            onChange('statusId')(_statusId)
            dispatch(statusLeadsEditOpen({ id: _statusId, dontOpen: true }))
                .unwrap()
                .then((data) => {
                    setOptions(data.substatus);
                });
        }
        return () => {
            //clean statusLeadsEditRedux redux
            dispatch(reset())
        };
    }, [_statusId]);

    useEffect(() => {
        if (result) {
            dispatch(reset({ result: null }));
            notification.success({ message: messages["saveSuccessfully"] });
            dispatch(statusLeadsEditOpen({ id: _statusId, dontOpen: true }))
                .unwrap()
                .then((data) => {
                    setOptions(data.substatus);
                });
        }

        if (error) {
            dispatch(reset({ error: null }));
            notification.error({
                message: messages["saveError"],
                description: messages[error] || error,
            });
        }
    }, [result, error]);

    const onSave = () => {
        const _statusId = statusId || formData?.statusId;
        dispatch(statusLeadsEditSave({id: _statusId, substatus: options}))
    };

    const onAddOption = () => {
        setOptions(opt => [...opt, {
            name: currInput
        }])
        setCurrInput('')
    };

    const onDeleteOption = (index) => () => {
        setOptions(opt => opt.filter(
            (v, i) => index !== i
        ))
    };

    const handleInputAdd = (e) => {
        setCurrInput(e.target.value)
    };

    const handleInputEdit = (index) => (e) => {
        setOptions(opts => {
            let _opts = [...opts];
            _opts[index].name = e.target.value
            return _opts
        })
    }

    return (
        <div className="col-12 border p-2">
            <div className="row mb-2">
                {_statusId ? (
                    <>
                        {options.map((opt, i) => (
                            <Space
                                key={i}
                                style={{
                                    display: "flex",
                                    marginBottom: 8,
                                }}
                                align="baseline"
                            >
                                <Input
                                    value={opt.name}
                                    onChange={handleInputEdit(i)}
                                    placeholder={
                                        messages["configStatusLeadsEdit_name"]
                                    }
                                />
                                {!opt?.id && <button
                                    className="btn btn-label-secondary"
                                    onClick={onDeleteOption(i)}
                                >
                                    {messages["delete"]}
                                </button>}
                            </Space>
                        ))}
                        <Space
                            style={{
                                display: "flex",
                                marginBottom: 8,
                            }}
                            align="baseline"
                        >
                            <Input
                                value={currInput}
                                onChange={handleInputAdd}
                                placeholder={
                                    messages["configStatusLeadsEdit_name"]
                                }
                            />
                            <button
                                type="button"
                                className="btn btn-label-primary"
                                onClick={onAddOption}
                                disabled={!currInput}
                            >
                                {messages["add"]}
                            </button>
                        </Space>
                    </>
                ) : (
                    <h4>{messages['forms_selectStatusFirst']}</h4>
                )}
            </div>
            <div className="flex mb-2">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onSave}
                >
                    {messages["save"]}
                </button>
            </div>
        </div>
    );
}

export default UpdateStatusProps;
