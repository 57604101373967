import { Checkbox } from 'antd';
import {useState, useEffect, useCallback} from 'react'
import TextProps from './TextProps';
import SelectProps from './SelectProps';
import NumberProps from './NumberProps';
import FileProps from './FileProps';
import DateProps from './DateProps';
import UpdateStatusProps from './UpdateStatus';

const DEFAULT_PROPS = {
    justRead: false,
    required: false,
    catalogId: null
}

function PropsSelector(props) {
    const { type, onChange, value } = props;
    const [_value, setValue] = useState({})
    const [currType, setCurrentType] = useState()
    useEffect(() => {
        if(value){
            setValue(value)
        }
    }, [value])

    useEffect(() => {
        setCurrentType(type)
        if(currType != undefined && type != currType){
            let newVal = {};
            for (const key in _value) {
                if (Object.hasOwnProperty.call(DEFAULT_PROPS, key)) {
                    newVal = {
                        ...newVal,
                        [key]:  _value[key]
                    }
                }
            }
            setValue(newVal)
            
            onChange(newVal)
        }
    }, [type])
    

    const onChangeValues = (key) => (newVal) => {
        setValue(curr => {
            const _newVal = {
                ...curr,
                [key]: newVal
            }
            onChange(_newVal)
            return _newVal
        })
    }

    const adicionalProps = useCallback((currType, oc, v) => {
        switch (currType) {
            case 'string':
                return <TextProps onChange={oc} values={v}/>
            case 'number':
                return <NumberProps onChange={oc} values={v}/>
            case 'catalog':
                return <SelectProps onChange={oc} values={v}/>
            case 'multiCatalog':
                return <SelectProps onChange={oc} values={v}/>
            case 'file':
                return <FileProps onChange={oc} values={v}/>
            case 'date':
                return <DateProps onChange={oc} values={v}/>
            case 'update_status':
                return <UpdateStatusProps onChange={oc} values={v} />
            default:
                return null;
        }
    }, [])

    return (
        <>
        <div className='col-12'>
            <div className='row mb-2'>
                <Checkbox onChange={(e) => onChangeValues('required')(e.target.checked)} checked={_value['required']}>Required</Checkbox>
            </div>
            {/* <div className='row mb-2'>
                <Checkbox onChange={(e) => onChangeValues('justRead')(e.target.checked)} checked={_value['justRead']}>Just Read</Checkbox>
            </div> */}
        </div>
        {adicionalProps(currType, onChangeValues, _value)}
        </>
    )
}

export default PropsSelector