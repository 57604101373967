import { Routes, Route, Navigate, useLocation } from 'react-router-dom';


import { ReportCSVListView } from './views/list';



const ReportCSVRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/management/reports/list`} />}></Route>
            <Route path='/list' element={<ReportCSVListView />} />
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default ReportCSVRouter;