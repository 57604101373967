import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";




//STATE INITIAL
const INIT_STATE = {
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null,
};

const route = "modules/management/parameters/modals/editStatusLeads/slice";



export const statusLeadsEditOpen = createAsyncThunk("statusLeadsEditOpen/editLeads/open",
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {

            if (!id) {
                return { data: null };
            }

            let data = await clientQuery(
                `query getAnnouncementStatusById($id: Int!) {
                    getAnnouncementStatusById(id: $id) {
                        id
                        name
                        description
                        substatus {
                            name
                            description
                            id
                        }
                    }
                }`,
                {
                    id: Number(id),
                },
                endpoints.GRAPHQL_GENERAL
            );

            return data.getAnnouncementStatusById
        } catch (exc) {
            ExceptionManager(exc, route, "statusLeadsEditOpen", dispatch);
            return rejectWithValue(exc);
        }
    });

export const statusLeadsEditSave = createAsyncThunk("tatusLeadsEditOpen/editLeads/save",
    async ({ id, name, description, substatus }, { rejectWithValue, dispatch }) => {

        try {

            let data;

            if (id) {
                //update
                data = await clientMutation(
                    `mutation updateAnnouncementStatus ($id: Int!, $name: String, $description: String, $substatus: [SubstatusInput]) {
                      updateAnnouncementStatus (id: $id, name: $name, description: $description, substatus: $substatus)
                          {
                            id
                          }
                      }`,
                    {
                        id,
                        name,
                        description,
                        substatus: substatus.map(v => ({id: v?.id, name: v?.name, description: v?.description }))
                    },
                    endpoints.GRAPHQL_GENERAL
                );
                
                return data.updateAnnouncementStatus.id
            }
        } catch (exc) {
            ExceptionManager(exc, route, "statusLeadsEditSave", dispatch);
            return rejectWithValue(exc);
        }

    });

//Slice
const statusLeadsEditRedux = createSlice({
    name: "parameters/editParametrizableText",
    initialState: INIT_STATE,
    reducers: {
        statusLeadsEditReduxClose() {
            return INIT_STATE;
        },
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //thunk editOpen
        builder.addCase(statusLeadsEditOpen.pending, (state,action) => {
            const { arg } = action.meta;
            state.loading = true;
            state.isOpen = arg?.dontOpen ? false : true;
        });
        builder.addCase(statusLeadsEditOpen.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        });
        builder.addCase(statusLeadsEditOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        //thunk save
        builder.addCase(statusLeadsEditSave.pending, (state) => {
            state.loading = true;
            state.result = null;
            state.error = null;
        });
        builder.addCase(statusLeadsEditSave.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.result = payload;
        });
        builder.addCase(statusLeadsEditSave.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = statusLeadsEditRedux;

// Extract and export each action creator by name
export const { statusLeadsEditReduxClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;


