import { Button, Form, Input, message, Modal, Spin, Steps } from 'antd'
import React, { useEffect, useMemo, useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dinamicFormClose, saveAnswersForm } from './redux/slice';
import FormQuestion from "../../../../../../components/FormQuestion";
import { DinamicInput } from '../../../../../../components/FormQuestion/DinamicInput';
import { useIntl } from 'react-intl';
import { dashboarNotesListSearch } from '../../.config/redux/actions';



export const DinamicFormByStep = ({ userAnnouncementId, refresh }) => {

  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState({})

  const { messages } = useIntl();

  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const { isOpen, data, loading, error, status } = useSelector(
    (state) => state.dinamicFormByStateRedux
  );

  const stepsQuestions = useMemo(() => data?.questions && Array.isArray(data?.questions) ? [
    ...data?.questions?.map((item, index) => {
      const props = JSON.parse(item?.props)
      return {
        title: item?.question,
        content: (
          <Form.Item
            name={item.id}
            key={item.id}
            preserve
            label=''
            initialValue={props?.defaultValue}
            rules={[{ required: props?.required, message: 'Campo obbligatorio' }]}
          >
            <FormQuestion {...item} />
          </Form.Item>
        )
      }
    }),
    {
      title: 'Confirmation',
      content: <div>probamos la confirmacion del modal</div>,
    },
  ] : [], [data])


  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    }).catch(() => { console.log('no complete') })
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const closeForm = () => {
    form.resetFields()
    setAnswers({})
    setCurrent(0)
    dispatch(dinamicFormClose())
  }

  return (
    <Modal
      open={isOpen}
      width={1200}
      footer={null}
      maskClosable={false}
      onCancel={() => {
        closeForm();
      }}
    >
      <div className='p-3 p-md-5'>
        <Spin spinning={loading}>
          <div className='text-center mb-4'>
            <h3>{data?.title}</h3>
            <div className='text-center'>
              <p> {data?.description} </p>
            </div>
          </div>
          <div className='card-body'>

            <Steps current={current} items={stepsQuestions} />
            <Form
              form={form}
              preserve
              onFinish={(values) => {
                let answerDefaultValues = {}
                data.questions.forEach((item) => {
                  const props = JSON.parse(item?.props)
                  answerDefaultValues = {
                    ...answerDefaultValues,
                    [item.id]: props?.defaultValue
                  }
                })
                //console.log({ ...answerDefaultValues, ...answers }, 'answers')
                // dispatch(dashboarNotesListSearch({ limit: 10, offset: 0, companyAnnouncementId: userAnnouncementId, isUserAnnouncement: true }))
                dispatch(saveAnswersForm({ formId: data?.id, answers: { ...answerDefaultValues, ...answers }, userAnnouncementId, newStatus: status }));
                refresh && refresh();
                closeForm();
              }}
              onValuesChange={(currChangedValue, values) => { setAnswers((actualValue) => ({ ...values, ...actualValue, ...currChangedValue })) }}
            >
              {
                stepsQuestions.length > 0 && stepsQuestions[current].content
              }
            </Form>

            <div
              style={{
                marginTop: 24,
              }}
              className='d-flex justify-content-between'
            >

              <Button
                style={{
                  margin: '0 8px',
                }}
                className='btn btn-label-secondary'
                onClick={() => current > 0 && prev()}
              >
                {messages["back"]}
              </Button>

              {current < data?.questions.length - 1 && (
                <Button className='btn btn-primary' type="primary" onClick={() => next()}>
                  {messages["next"]}
                </Button>
              )}
              {current === data?.questions.length - 1 && (
                <Button className='btn btn-primary' type="primary" onClick={() => form.submit()}>
                  {messages["companiesSendEmail_Finish"]}
                </Button>
              )}

            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  )
}
