let locales = {
    "reportCSV_haveAcquierd": "HANNO ACQUISTATO",
    "reportCSV_actionsPerformed": "AZIONI ESEGUITE",
    "reportCSV": "Exporta csv",
    "reportCSV_firstName": "Nome",
    "reportCSV_lastName": "Cognome",
    "reportCSV_phone": "Telefono",
    "reportCSV_email": "Email",
    "reportCSV_tags": "Tag",
    "reportCSV_city": "Città",
    "reportCSV_state": "Stato",
    "reportCSV_province": "Provincia",
    "reportCSV_titleRequest": "Titolo Richiesta",
    "reportCSV_dataRequest": "Data Richiesta",
    "reportCSV_categorie": "Categorie",
    "reportCSV_description": "Descrizione",
    "reportCSV_expectedProjectDate": "Data Prevista del Progetto",
    "reportCSV_requestType": "Tipo di Richiesta",
    "reportCSV_whereIsNeedit": "Dove gli Serve",
    "reportCSV_searchthing": "Cosa Sta Cercando",
    "reportCSV_inspection": "Sopralluogo",
    "reportCSV_requestCode": "Codice Richiesta",
    "reportCSV_desingAndEvaluation": "Progettazione e/o Valutazione",
    "reportCSV_requestPermissions": "Richiesta Permess",
    "reportCSV_receiveQuotations": "Voglio ricevere preventivi solo da aziende della mia zona (Provincia)"
};

export default locales;