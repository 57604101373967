import { useEffect, useState } from "react"
import { Select } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { statusLeadsEditOpen } from "../../config/redux/actions";
//import {reset} from '../../modules/admin/management/parameters/modals/editSatutsLeads/redux/slice'


const UpdateStatus = (props) => {
  const { statusId, /* questionType, */ value = {type: 'updateStatus', value: null, label: ''}, onChange } = props;
  const dispatch = useDispatch();
  const { data } = useSelector((st) => st.statusLeadsEditRedux);

  const [_value, setValue] = useState(value);

  useEffect(() => {
    if(value?.value && value.value !== _value.value) {
      setValue(value)
    }
  }, [value])

  useEffect(() => {
    //get subStates
    if (statusId) {
        dispatch(statusLeadsEditOpen({ id: statusId, dontOpen: true }))
    }
    return () => {
        //clean statusLeadsEditRedux redux
        //dispatch(reset())
    };
  }, [statusId]);

  const _onChange = (selectedValue, option) => {
    const newValue = {type: 'updateStatus', value: selectedValue, label: option.name};
    setValue(newValue)
    if(onChange){
      onChange(newValue)
    }
  }

  return (
    <div className="row">
        <Select options={data?.substatus || []} value={_value.value} onChange={_onChange} fieldNames={{label: 'name', value: 'id'}} />
    </div>
  )
}
export default UpdateStatus;