import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Modal, notification, Spin} from "antd";
import { detailNoteClose, reset } from "./redux/slice";
import { RenderAnswersForm } from "../../../../../../components/RenderAnwsersForm";

export default function (props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { isOpen, data, loading, error } = useSelector(
    (state) => state.announcementNoteDetailRedux
  );

  useEffect(() => {
    if (error) {
      dispatch(reset({ error: null }));
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
  }, [error]);

  const closeModal = useCallback((refresh) => {
    dispatch(detailNoteClose());
    onClose && onClose(refresh);
  });

  return (
    <Modal
      open={isOpen}
      width={800}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      maskClosable={false}
    >
      <div className="p-3 p-md-5">
        <div className="text-center mb-4">
          <h3>{messages['announcementNoteDetail_detail']}</h3>
        </div>

        <Spin spinning={loading}>
          <div className="card-body">
            {data?.form ?
              <div>
                <RenderAnswersForm answers={data?.formAnswer} form={data?.form} />
              </div> :
              <p className="mb-0">{data?.note}</p>
            }
          </div>
        </Spin>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <button
              className="btn btn-label-secondary"
              onClick={() => closeModal()}
            >
              {messages["close"]}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
