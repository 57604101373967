import { Select } from "antd"
import { useMemo } from "react"


const SelectInput = (props) => {

  const { catalog, questionType, value, onChange } = props;
  const defaultOptions = useMemo(() => {
    return catalog?.items.map((option) => {
      return {
        label: option?.value,
        value: option?.value
      }
    })
  }, [props, catalog])

  return (
    <div className="row w-100">
      {
        questionType == 'MULTISELECT' ? <Select mode="multiple" options={defaultOptions} value={value} onChange={onChange} />
          : <Select options={defaultOptions} value={value} onChange={onChange} />
      }
    </div>
  )
}
export default SelectInput